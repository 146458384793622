@import"colors.scss";

.required.ng-valid:not(form) {
  border-left: 5px solid $field-validation-success-color; /* green */
}

.required.ng-invalid:not(form) {
  border-left: 5px solid $field-validation-fail-color; /* red */
}

.form-control {
  border-color: #909090; /* accessible grey*/
}

.form-control:focus {
  border-color: #1B75BC; /* accessible blue for focus*/
}
