/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/font-awesome/css/font-awesome.min.css";

@import '../node_modules/ng-pick-datetime-ex/assets/style/picker.min.css';
@import "vis-network/styles/vis-network.min.css";

// styles for ngx-datatable.
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import "./style/datatable-styles.scss";
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

// should be the last import, to override all other imported scss/css files.
@import "style/common-styles";

iframe{
    border: none;
}
