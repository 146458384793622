/*
styles to data-table have to be applied as global styles.
*/

.ngx-datatable.bootstrap {
    box-shadow: none;
    font-family: $font-family-base !important;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    font-size: 1rem !important;
    border-top: $table-border-width solid $table-dark-border-color;
    .datatable-header {
      height: unset !important;
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
      .datatable-header-cell {
        vertical-align: bottom;
        padding: $table-cell-padding;
        border-bottom: $table-border-width solid $table-dark-border-color;
        border-right: $table-border-width solid $table-dark-border-color;
        .datatable-header-cell-label {
          display: table-cell;
        }
      }
    }
    .datatable-body {
      .datatable-body-row {
        vertical-align: middle;
        border-bottom: $table-border-width solid $table-border-color;
        border-left: $table-border-width solid $table-border-color;
        border-right: $table-border-width solid $table-border-color;
        &.datatable-row-even {
          background-color: $table-accent-bg;
        }
        &.active {
          background-color: #1483ff;
          color: #fff;
        }
        .datatable-body-cell {
          height: 100% !important;
          border-right: $table-border-width solid $table-border-color;
          padding-left: $table-cell-padding;
          padding-top: $table-cell-padding;
          padding-right: $table-cell-padding;
          padding-bottom: $table-cell-padding;
          text-align: left;
          vertical-align: middle;
          display: table-cell;
          .text-center {
              text-align: center;
          } 
        }
      }
      .empty-row {
        $alert-padding-y: 0.75rem !default;
        $alert-padding-x: 1.25rem !default;
        position: relative;
        padding: $alert-padding-y $alert-padding-x;
        margin-bottom: 0;
      }
    }
    .datatable-footer {
      background: #424242;
      color: #ededed;
      margin-top: -1px;
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
      }
      .datatable-pager {
        margin: 0 10px;
        vertical-align: top;
        ul {
          li {
            margin: 10px 0px;
            &:not(.disabled) {
              &.active,
              &:hover {
                a {
                  background-color: #545454;
                  font-weight: bold;
                }
              }
            }
          }
        }
        a {
          height: 22px;
          min-width: 24px;
          line-height: 22px;
          padding: 0;
          border-radius: 3px;
          margin: 0 3px;
          text-align: center;
          vertical-align: top;
          text-decoration: none;
          vertical-align: bottom;
          color: #ededed;
        }
        .datatable-icon-left,
        .datatable-icon-skip,
        .datatable-icon-right,
        .datatable-icon-prev {
          font-size: 18px;
          line-height: 27px;
          padding: 0 3px;
        }
      }
    }
    // Summary row styles
    .datatable-summary-row {
      .datatable-body-row {
        .datatable-body-cell {
          font-weight: bold;
        }
      }
    }
  }
  